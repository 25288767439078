import { getCustomField } from "@/lib/bigcommerce/utils/bigcommerceCustomFieldsUtils";
import { ProductData } from "@/lib/5874/types";
import { storesWithCertificationChecks } from "@/lib/bigcommerce/config/stores";
import { StoreRegion } from "@/lib/builder/builder.config";
import { ProductListItemType } from "@/lib/bigcommerce-b2b/types";

export const userHasRequiredCertifications = (
	store: StoreRegion,
	product: ProductData | ProductListItemType | undefined,
	companyCourseCertification?: string[],
	// certificationsRequired?: string[],
) => {
	if (!product) return true;
	if (!storesWithCertificationChecks.includes(store)) return true;

	const certificationsRequired = getCustomField(
		product,
		"Akeneo|CourseCertification",
	)
		?.value?.split(",")
		.map((cert) => cert.trim());

	const cleanCompCerts = companyCourseCertification?.map((c) =>
		c.trim().replace(/[_ ]/g, "").toUpperCase(),
	);
	const cleanCertsRequired = certificationsRequired?.map((c) =>
		c.trim().replace(/[_ ]/g, "").toUpperCase(),
	);

	if (!cleanCertsRequired?.length) return true;

	return (
		!!cleanCompCerts &&
		cleanCertsRequired.some((cert) => cleanCompCerts.includes(cert))
	);
};

export const requiredCertificationMessaging = (product: ProductData) => {
	const field = getCustomField(product, "Akeneo|CourseCertification")
		?.value?.split(",")
		.map((cert) => cert.trim());

	const str = field?.join(", ");
	const isPlural = field && field?.length > 1;

	return `${isPlural ? "One of " : ""}${str} certification${
		isPlural ? "s" : ""
	} is required to purchase this product`;
};
